nav{
    width: 100%;

    background: linear-gradient(86deg, #0749A2 0%, #5CE1E6 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    animation: display 0.5s ease;
    height: var(--nav);
    position: fixed;
    top: 0;
    height: var(--nav);
    z-index: 10;
    transition: all 0.3s;

}

nav *{
    color: var(--white-color);
}

nav h2{font-size: clamp(1rem, 1.4vw + .1rem, 1.4rem); cursor: pointer;}
nav a{font-size:  clamp(0.9rem, 1.2vw + .1rem, 1.2rem); font-family: "Montserrat-SemiBold";}

@keyframes display {
    from{
       transform: translateY(-100px);
    }
    to{
        transform: translateY(0);
    }
}


@media (min-width : 920px) {
    
    .nav--hidden {
      transform: translateY(calc(-1 * var(--nav)));
    }
  
    .scrolled-up{
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
        opacity: 0.9;
    }
    

}