
footer{
    width: 100%;
  color: var(--white-color);
    display: flex;
    padding-bottom: 100px;
    align-items: center;
    flex-direction: column;
    margin-top: 300px;
     background-repeat: no-repeat;
    background-size: 100%;  
    background-image:url("../../assets/bg3.svg");
    background-position: bottom;
    text-align: center;
  }
  
  footer .logo{
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
  footer > div{
    position: relative;
    top: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  footer > div .callto{
    margin-top: 40px;
  }
  
  footer h3, footer h4{
    margin: 5px;
  }
  
  footer h3{
    font-size: clamp(1rem, 1.2vw + .1rem, 1.4rem);

    font-family: "Montserrat-SemiBold";
    
  }
  
  footer h4{
        font-size: clamp(0.8rem, 1vw + .1rem, 1rem);

  }
  footer a{
    font-family: "Montserrat-Medium";
    color: var(--white-color);
    font-size: clamp(0.8rem, 1vw + .1rem, 1rem);
    transition:  0.2s;
  }

  footer a:hover{
    opacity: 0.8;
  }

  @media (max-width: 800px) {

    footer{

        background-size: auto;
        background-position: top;
    }

    footer .logo{
      left: auto;
      transform: none;
    }
  }

  @media(max-width : 600px){
    footer h3{
      width: 90%;
    }
  
  }


@media (min-width: 1780px) {

  footer{
    background-position: top;
  }
}


