.calender .calendercontainer{
    flex: 0.5;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    padding: 10px;
    border-radius: 20px;
    background-color: #EDF1F5;box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
}

.calender .section4{
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.row.section4 > div:nth-child(2){
    align-items: start;
    flex: 0.5;

}

.pdp{
    width: 160px;
    height: 160px;
    border-radius: 201px;
    border: 5px solid var(--main);
    margin-bottom: 50px;
    object-fit: cover;
}

.calender h2{
    color: var(--main);
}

.calender ul{
    list-style: none;
    display: flex;
    gap: 20px;
flex-direction: column;
padding: 0;}

.calender li{
   display: block;
   position: relative;
   padding-left: 35px;

}

.calender li ion-icon{
    position: absolute;
    font-size: 25px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--main);
   
}

.calender{
    width: 80%;
    scroll-margin-top: 50px;

}



._3efP_GeH5kyBAzqnLzL.w_Mtb2W9166EgOSv9i3M{
    border: 0 !important;
}




@media (max-width: 800px) {

.calendercontainer{
    width: 100%;
}
.calender h1{
    margin-left: 0 !important;
}
.pdp{
    margin-bottom: 30px;
}
}