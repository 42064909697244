
form{    width: clamp(400px, 85vw, 900px);
}
form h1{
    margin-top: 0;
}
form .fields {
    gap: 50px;
    display: flex;
    flex-direction: row;
}
form .fields .content{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}


form .fields .content .field{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
input, textarea{
    border-radius: 10px;
border: 2px solid #004AAD;
background: rgba(0, 74, 173, 0.00);
padding: 10px;
}

label{
    font-family: "Montserrat-SemiBold";

}
textarea{
    height: 250px;
    resize: none;
}
input:focus, textarea:focus{
    outline: none;
}
.left-border{
    display: block;
    background: linear-gradient(180deg, #0450B0 0%, #8FEAEC 100%);
    width: 13px;
}



.contact .callto{
    margin: 0;
   margin-top: 20px;
   margin-left: auto;
}

@media (max-width: 800px) {
    .contact.landing-fragment{
        width: 100%;
    }
    .left-border{
        display: none;
    }
    form{
        width: 90%;
        margin: auto;
    }

}