:root{
  --bg-color : #FFFDFB;
  --txt-color : #000000;
  --white-color : #FFFDFB;
  --main : #2e517e;
  --secondary : #8FEAEC;  --nav : 60px;


}


* {
  font-weight: normal !important;
  box-sizing: border-box;
}


body{
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  overflow-x: hidden;
}

.App{
  color: var(--txt-color);
  width: 100vw;  min-height: 100vh;

  overflow: hidden;
}

span{
  color: var(--secondary) !important;
}

a{
  text-decoration: none;
}


@font-face {
  font-family: "Montserrat-Bold";
  src: url("../public/Montserrat/Montserrat-Bold.ttf")format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../public/Montserrat/Montserrat-SemiBold.ttf")format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../public/Montserrat/Montserrat-Medium.ttf")format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../public/Montserrat/Montserrat-Regular.ttf")format('truetype');
  font-display: swap;
}



h1{
  font-size: clamp(1.3rem, 2.4vw + .1rem, 2.6rem);

}


h2{
  font-size: clamp(1.1rem, 2.2vw + .1rem, 2.2rem);

}

p, input, textarea, select, option,li{
  font-size: clamp(1rem, 1.22vw + .1rem, 1.3rem);

  
}

input,textarea{
  font-family: "Montserrat-Medium";

}

h1, h2, a{
  font-family: "Montserrat-Bold";
}
p,li{
 font-family: "Montserrat-Medium";

}

.page{
  padding-top: calc(var(--nav) + 50px);
 
 width: 100%;
 position: relative;
 margin-top: 150px;
}
.page.contact{margin-top: 100px;}
.landing-fragment{
  width: 85%;
  margin: auto;
}

.very{
  font-size: clamp(1.2rem, 2.6vw + .1rem, 3rem);
  position: relative;
  top: 10px;
  z-index: 1;
}
.contact.landing-fragment{
  width: 90%;
}
.row{
  display: flex;
  flex-wrap: wrap;
justify-content: space-between;

}

.white{
  color: var(--white-color);
}

.row > div{
  flex: 0.8;

}
.row > div:nth-child(2){
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex : 0.6
}
.logo{
  width: clamp(200px, 35vw, 405px);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
  top: -50px;
}



.bg1{
  display: flex;
  align-items: center;
  color: var(--white-color);
  width: 100%;
  background-repeat: no-repeat;
  position: absolute;
  background-size: 100%;
  left: 0;
  height: clamp(20px, 40%, 359px);
  background-image:url("./assets/bg1.svg");

background-position:left}



.bg1 div {
  width: 85%;
  margin: auto;
  display: flex;
  align-items: center;
}
.bg1 div h1{
width: 57%;
position: relative;
top: 0;
}


.home h1{
  margin-bottom: 0;
}
.home .content{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: clamp(20px, 100%, 310px);
 
}



.sections{
  margin-top: 300px;
}

.row.section2 > div{
justify-content: start;
align-items: start;
}

.row.section2, .row.section3 {
  gap: 100px;
}
.ilu{
 width: clamp(200px, 35vw, 440px);
 flex: 0.5;
 object-fit: cover;
 margin: auto;
}


.row > div.ilu{
  
  flex : 0.5
}

.row.section3 .ilu{ 
  flex: 0.5;
}

.row.section3 > div:nth-child(1){
flex: 1
}

.fullplace{
  display: flex;
  align-items: center;
  width: 100%;background-repeat: no-repeat;
  background-size: cover;  
  background-image:url("./assets/bg2.svg");
  height: 827px;
  background-position: top;
  margin-top: 125px;
}
.fullplace .section3{
  width: 85%;
  margin: auto;
  position: relative;
  top: 100px;

}
.fullplace .section3 > * {
  color: var(--white-color);
}


span.little{
  font-family: "Montserrat-Medium";
  color: var(--white-color) !important;
  
}


@media (min-width: 1700px) {
  .bg1{
    background-position: initial !important;
    height: 450px !important;
  }

  .very{
    top: 20px !important;
  }

  .page .logo{
top: 20px;
  }

  .home .content{
    top: 400px;
  }
  
}


@media (min-width: 1600px) {


  .very{
    top: 0px;
  }
}

@media (max-width: 1400px) {

  .bg1 div h1{
    margin-top: 0;
  }
  .very{
    top: clamp(20px, 50%, 55px);
  }
}

@media (max-width: 1000px) {
  .very{
    top: 70px;
  }
}

@media (max-width: 800px) {

  .bg1{
    background-size: auto;
    position: relative;
 
      background-position: center;
  
    height: clamp(100px, 18vh, 250px);


  }

  .row.section2{
    gap: 20px;
  }
  .very{
    top: -10px;
  }
  .row:not(.answer .row){
    flex-direction: column-reverse
    
  }
  .bg1 div h1{
    width: 100%;
    position: relative;top: 0px;
    
    }
    .landing-fragment{
      width: 100%;
    }
    .page{
      margin-top: 0px;
    }
    .home h1:not(.fullplace .section3 h1, .bg1 h1), .home .content{
      width: 85%;
      margin: auto;
    }
    .bg1 div h1{
      margin-top: 0;
    }

    .home .landing-fragment{
      gap: 50px;
      margin-top: 150px;
    }
    .fullplace, .sections{
      margin-top: 100px;
    }

    .section2 p{
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      
      }

   
      .home .landing-fragment.brands-section{
        gap: 0;
      }
      .home .content{
      margin-top: 30px;
      }
      
}


@media (max-width: 620px) {
  .fullplace{
    padding: 0 20px;
    padding-bottom: 50px;
  }
  .page{
    padding-top: var(--nav);
  }


}

.bold{
  font-family: "Montserrat-Bold";
}

.bold span{
  color: var(--main) !important;
  border-bottom: 3px solid var(--secondary);
}


.items-container{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 50px 150px;
  margin-top: 80px;
  height: 250px;
}


@media (min-width: 1910px) {

  .items-container{
    width: 80%;
    max-width: 1500px;
  }
}

.items-container ion-icon{
  font-size: 30px;
}

.items-container p{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-left: 3px solid var(--secondary);
  padding-left: 10px;
  cursor: pointer;
  transition: 0.3s;
  padding: 10px;
}

.items-container p:hover{
  transform: translate3d(0px, 0px, 0px) scale3d(1.04, 1.04, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  background-color: var(--secondary);
  color: var(--main);
}

.items-container ion-icon[name="information-circle-outline"]{
  font-size: 20px;
  
}

.back{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.items-container .row{
  gap: 50px;
  justify-content: start;
}


@media (max-width: 1138px) {

.items-container{
  height: 300px;

}
}

@media (max-width: 1138px) {

  .items-container{
    height: 300px;
  
  }
  }


  @media (max-width: 848px) {
    .fullplace{
      height: fit-content;
      margin-top: 0px;
      background-size: auto;
      background-position:initial;
      padding: 50px 20px;
    }
    .items-container{
      height: 500px;
      gap: 20px;
      justify-content: space-between;
      margin-top: 50px;
    }
    .items-container .row{
      gap: 0px 20px;
    }
  }
