.callto{
    border-radius: 20px;
background: #8FEAEC;
color: var(--white-color);
border: 0;
padding: 20px 40px;
display: block;
margin: auto;
font-family: "Montserrat-Bold";
font-size: clamp(1rem, 1.4vw + .1rem, 1.4rem);;
cursor: pointer;
transition: all 0.3s;
position: relative;

}

.section2 .callto, .section3 .callto{
    margin-top: 50px;

}

.callto:hover{
    transform: translate3d(0px, 0px, 0px) scale3d(1.04, 1.04, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    background : var(--main);
    transform-style: preserve-3d;
}